import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import goku1_an2 from './../../../../images/goku1_an2.gif';
export const _frontmatter = {
  "title": "Gokou",
  "path": "/gokouinfo.html"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="text-center">{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img className="mx-auto" src={goku1_an2} />
    <p><strong parentName="p">{`SON GOKOU`}</strong></p>
    <p>{`Gokou is a great guy. Also, the strongest guy in history, besides the fusion. He has a great heart. He's the nicest guy and DB, DBZ, and DBGT. Gokou, in the earlier episodes he sorta was an idiot. On the first episode of GT, Gokou gets turned into a kid. Gokou achives each level of Super Saiyjin in the show.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      